<template>
    <div class="not-found-all">
        <div class="novue">
            <img src="@/assets/error.png" alt="">
            <div>
                <p>{{lang == 'zh'?'操作异常！':'Suspicious Activity Detected!'}}</p>
                <p>{{lang == 'zh'?'您的手机号码已被银行系统重点监控中，无法正常下单。':'Your phone number is under strict surveillance by the bank’s security system. Orders cannot be processed.'}}</p>
             </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'error',
    computed: {
        lang() {
            return  localStorage.getItem('lang') || 'zh';
        },
    },
    methods: {
        checkUserStatus() {
            return sessionStorage.getItem('isIllegalUser') === 'true'
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (!vm.checkUserStatus()) {
                vm.$router.replace('/')
            }
        })
    },
    beforeRouteLeave(to, from, next) {
        if (this.checkUserStatus()) {
            next(false)
            this.$router.replace('/error')
        } else {
            next()
        }
    },
}
</script>

<style scoped lang="less">
.not-found-all{
    width: 100%;
    height: 100%;
    position: relative;
    .novue {
        width: 300px;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        img {
            width: 220px;
            display: block;
            margin: auto;
        }
        div {
            p {
                &:first-child {
                    color: #000;
                    font-size: 20px;
                    margin: 15px;
                }
                &:last-child {
                    color: #333;
                    font-size: 16px;
                }
            }
        }
    }
}
</style>